import Swiper, { Navigation, Pagination, EffectCards } from 'swiper'
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import Colcade from  '../../node_modules/colcade/colcade.js';
import MicroModal from 'micromodal';
import ScrollOut from 'scroll-out';
import bodymovin from "lottie-web";

// import Vue from 'vue';
// import Vue from 'vue/dis/vue.js';


Swiper.use([Navigation, Pagination, EffectCards])

document.addEventListener('DOMContentLoaded', () => {

    // MicroModal.init();
    MicroModal.init({
        awaitCloseAnimation: 'true',
        onClose: modal => stopVideo(modal),
        onShow: modal => playVideo(modal),
    });

    var stopVideo = function (modal) {
        // Look for video iframe in the modal
        var videoIframe = modal.querySelector('lite-youtube');
        var liteVimeo = modal.querySelector('lite-vimeo');
        if (videoIframe){
            const searchModuleRoot = videoIframe;
            var video = searchModuleRoot.querySelector('iframe');
            // Bail if the modal doesn't have a video
            if (!video) return;
            // If an HTML5 video, pause it
            if (video) {
                console.log(video);
                // Change autoplay to stop
                video.src = video.src.replace('?autoplay=1', '?autoplay=0');
                video.remove();
            }
        }
        if (liteVimeo){
            const searchModuleRoot = liteVimeo.shadowRoot;
            var video = searchModuleRoot.querySelector('iframe');
            // Bail if the modal doesn't have a video
            if (!video) return;
            // If an HTML5 video, pause it
            if (video) {
                // Change autoplay to stop
                video.src = video.src.replace('?autoplay=1', '?autoplay=0');
            }
        }
    };

    var playVideo = function (modal) {
        // Look for video iframe in the modal
        var videoIframe = modal.querySelector('lite-youtube');
            if (videoIframe){
            // Look for a YouTube, Vimeo, or HTML5 video in the modal
            var video = modal.querySelector('lite-youtube');
            if (!video) return;
            console.log(video);
            const child = video.querySelector('.lty-playbtn');
            console.log(child);
            child.click();
        }
    };


    // Mobile navigation
    const navTrigger = document.querySelector('.js-open-nav');
    const mobNav = document.querySelector('.mobile-menu');

    if(navTrigger){
        bindEvent(navTrigger, "click", function(event) {
            event.preventDefault();
            document.body.classList.toggle('modal-open');
            navTrigger.classList.toggle('is-active');
            mobNav.classList.add('mobile-transition');
            mobNav.classList.toggle('is-active');
            navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
        }, false);
    }


    // Mobile navigation - sub menus
    const menuItems = document.querySelectorAll('[data-menu-item]');

    if (menuItems.length) {
        menuItems.forEach(function(menu) {
            const menuLink = menu.querySelector('[data-menu-link]');
            menu.classList.contains('is-active') ? toggleMenu(menuLink) : '';
            bindEvent(menuLink, 'click', toggleMenu);
        });
    }

    function toggleMenu(e) {
        const link = e.target ? e.target : e ;
        const parent = link.closest('[data-menu]');
        const content = parent.querySelectorAll('[data-menu-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Swiper
    // var swiper = new Swiper(".careerSwiper", {
    //     effect: "cards",
    //     grabCursor: true,
    //
    //     pagination: {
    //         el: ".swiper-pagination",
    //         type: "fraction",
    //     },
    // });


    // const careerFilterOptions = document.querySelectorAll('.swiper-options');
    // if(careerFilterOptions.length){
    //     var currentActiveSwiper = document.querySelector('.swiper-slide-active');
    //     var swiperPosition = currentActiveSwiper.getAttribute('data-swiper-index');
    //     for(var i = 0; i < careerFilterOptions.length ; i++) {
    //         if(careerFilterOptions[i].getAttribute("data-option-index") == swiperPosition){
    //             careerFilterOptions[i].classList.remove("hidden");
    //         }else{
    //             careerFilterOptions[i].classList.add("hidden");
    //         }
    //     }
    // }

    // swiper.on('slideChange', function () {
    //     var currentActiveSwiper = document.querySelector('.swiper-slide-active');
    //     var swiperPosition = currentActiveSwiper.getAttribute('data-swiper-index');
    //     console.log('slide changed');
    //     console.log('onSlideChangedEnd', this.activeIndex);
    //     var onSlideChangedEnd = this.activeIndex;
    //     console.log("swiperPosition "+swiperPosition);
    //     var nextDisplayedSwiper = onSlideChangedEnd + 1;
    //     console.log("nextDisplayedSwiper: "+nextDisplayedSwiper);
    //
    //     if(swiperPosition > onSlideChangedEnd){
    //         console.log("swiped right");
    //     }else{
    //         console.log("swiped left");
    //     }
    //
    //     for(var i = 0; i < careerFilterOptions.length ; i++) {
    //         if(careerFilterOptions[i].getAttribute("data-option-index") == nextDisplayedSwiper ){
    //             careerFilterOptions[i].classList.remove("hidden");
    //         }else{
    //             careerFilterOptions[i].classList.add("hidden");
    //         }
    //     }
    // });


    const testimonialSwiperExists = document.querySelector('.testimonialSwiper');

    if(testimonialSwiperExists) {
        var swiper = new Swiper(".testimonialSwiper", {
            grabCursor: true,

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

        });
    }

    const timeLineSwiperExists = document.querySelector('.timeLineSwiper');

    if(timeLineSwiperExists) {
        var swiper = new Swiper(".timeLineSwiper", {
            grabCursor: true,

            navigation: {
                nextEl: ".swiper-button-next-timeline",
                prevEl: ".swiper-button-prev-timeline",
            },
        });
    }

    const otherRolesSwiperExists = document.querySelector('.otherRolesSwiper');

    if(otherRolesSwiperExists) {
        const breakpoint = window.matchMedia( '(max-width:767px)' );// keep track of swiper instances to destroy later

        let roleSwiper;

        const breakpointChecker = function() {

            if ( breakpoint.matches === true ) {

                if ( roleSwiper !== undefined ) roleSwiper.destroy( true, true );

                return;

            } else if ( breakpoint.matches === false ) {

                return enableSwiper();

            }};

        const enableSwiper = function() {
            roleSwiper = new Swiper(".otherRolesSwiper", {
                grabCursor: true,

                slidesPerView: 2,
                spaceBetween: 16,

                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },

                    1600: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                },

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },

            });
        };

        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);// kickstart
        breakpointChecker();

    }


    const swiperGalleryExists = document.querySelector('.swiperGallery');

    if(swiperGalleryExists) {
        var swiperGallery = new Swiper('.swiperGallery', {
            navigation: {
                nextEl: '.swiper-arrow-next',
                prevEl: '.swiper-arrow-prev',
            },
            pagination: {
                el: '.swiper-arrow-pagination',
                type: 'fraction',
            },
        });
    }

    const swiperTileExists = document.querySelector('.tileSwiper');

    if(swiperTileExists) {
        var tileSwiper = new Swiper('.tileSwiper', {
            slidesPerView: 1,
            spaceBetween: 0,

            breakpoints: {
              
                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
              
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }


    // Overlay gallery
    const overlayTriggers = document.querySelectorAll('.js-open-overlay');
    const overlayGallery = document.getElementById('overlay-gallery');
    const close = document.getElementById('js-close-modal');
    const modal = document.querySelector('.js-modal');
    const app = document.getElementById('app');

    if(overlayTriggers.length){
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayGallery.classList.add('show');

                // const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                // app.style.position = 'fixed';
                // app.style.top = `-${scrollY}`;
                const slideNumber = this.getAttribute('data-position');

                swiperGallery.slideTo(slideNumber,0);
            });
        }

        close.addEventListener('click', function(e){
            e.stopPropagation();
            overlayGallery.classList.remove('show');

            // const scrollY = app.style.top;
            // app.style.position = '';
            // app.style.top = '';
            //window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayGallery.addEventListener('click', () => {
            overlayGallery.classList.remove('show');

            //const scrollY = app.style.top;
            //app.style.position = '';
            //app.style.top = '';
            //window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        //Commenting this for testing
        // window.addEventListener('scroll', () => {
        //     document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        // });
    }

    // Tabs
    const sectionTabs = document.querySelector('.section-project-overview');

    if (sectionTabs) {

        function TabsNavigation() {

            var bindAll = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].addEventListener('click', change, false);
                }
            }

            var clear = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].classList.remove('active');
                    var id = menuElements[i].getAttribute('data-tab');
                    document.getElementById(id).classList.remove('active');
                }
            }

            var change = function(e) {
                clear();
                e.target.classList.add('active');
                var id = e.currentTarget.getAttribute('data-tab');
                document.getElementById(id).classList.add('active');
            }

          bindAll();
        }

        TabsNavigation();
    }

    // Filters
    const sectionFilter = document.querySelector('.section-industry-filter');

    if (sectionFilter) {

        const tag = document.querySelectorAll('.section-industry-filter .tag');

        function siblings(elem) {
          const nodes = [...elem.parentNode.children]
          return nodes.filter(node => node !== elem)
        }

        function toggleClass(elem, cls) {
          elem.classList.toggle(cls);
          siblings(elem).forEach(node => {
            node.classList.remove(cls)
          })
        }

        tag.forEach(el => {
          el.addEventListener('click', function() {
            toggleClass(this, 'active')
          })
        })
    }  

    // wrapping iframe in responsive div
    const iframeWrap = document.querySelectorAll('.wysiwyg-block iframe')

    iframeWrap.forEach(item => {  
        const wrapper = document.createElement("div");
        item.parentNode.insertBefore(wrapper, item);
        wrapper.appendChild(item);

        item.parentNode.classList.add('responsive-embed');
    });  

    // Accordions //
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });

        function toggleAccordion(e) {

            if(e.target.parentElement.classList.contains('accordion-item--active')) {
                e.target.parentElement.classList.remove('accordion-item--active');
                return;
            }
            accordionContentPanes.forEach(function(content) {
                if (content.previousElementSibling === e.target) {
                    if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                        content.classList.remove('accordion-item--active');
                    }
                    content.parentElement.classList.add('accordion-item--active');
                } else {
                    if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                        content.parentElement.classList.remove('accordion-item--active');
                    }
                    content.classList.add('accordion-item--active');
                }
            });
        }
    }


    // Scroll effects
    ScrollOut({
        targets: '[data-scroll]',
        once: 'true'
    });

    //tile-video play and pause
    const tileVideos = document.querySelectorAll(".tile-video");
    if(tileVideos){

        for (var i = 0; i < tileVideos.length; i++) {
            tileVideos[i].addEventListener('mouseleave', (event) => { 
                var videoFrame = event.target.querySelector('video');
                if (videoFrame){
                    videoFrame.pause();
                    videoFrame.currentTime = 0;
                }
            });
            tileVideos[i].addEventListener('mouseenter', (event) => { 
                var videoFrame = event.target.querySelector('video');
                if (videoFrame){
                    videoFrame.currentTime = 0;
                    videoFrame.play();
                }
            });
        }
    }

    // Bumper Videos 
    // Rules: Each video should play 5 sec one after the other and show the still after played!
    // const bumperVideo = document.querySelectorAll(".bumper-video__video");
    // if(bumperVideo){
    //     //Set start time as 0, so as to start when the page is ready
    //     var timeOut = 0; 
    //     loopBumperVideo(timeOut);
    // }
    // function loopBumperVideo(timeOut){
    //     var timeOut = timeOut;
    //     var totalVideos =  bumperVideo.length;
    //     for (var i = 0; i < totalVideos; i++) { 
    //         let videoFrame = bumperVideo[i];
    //         let previousVideoFrame = bumperVideo[i - 1];
    //         let lastVideoFrame = bumperVideo[totalVideos - 1];
    //         if(videoFrame){
    //             // videoFrame.classList.remove("hidden");
    //             //Going to sleep and play video after 5 sec
    //             setTimeout(() => {
    //                 videoFrame.classList.remove("hidden");
    //                 //Show the still image again after playing video
    //                 if(previousVideoFrame != undefined ){
    //                     // previousVideoFrame.load();
    //                     previousVideoFrame.classList.add("hidden");
    //                     // previousVideoFrame.load();
    //                 }else{
    //                     // lastVideoFrame.load();
    //                     // lastVideoFrame.classList.add("hidden");
    //                     // lastVideoFrame.load();
    //                 }
    //                 //Wake up and play the video!
    //                 videoFrame.currentTime = 0;
    //                 videoFrame.play();
    //             }, timeOut);
    //             timeOut = timeOut + 2000;
    //             //Call the same function again to repeat the same process when its the last video.
    //             if(i == (bumperVideo.length - 1)){
    //                 lastVideoFrame.classList.add("hidden");
    //                 setTimeout(() => {
    //                     loopBumperVideo( timeOut = 0);
    //                 }, timeOut)
    //             }
    //         }
    //     }
    // }


    // Form fields and labels
    var formLabels = document.querySelectorAll('.form-input');

    if (formLabels) {
        formLabels.forEach(function(element) {

            // Add filled class if page gets reloaded
            if (element.getAttribute('placeholder')!='') {
                element.previousElementSibling.classList.add('form-label--filled');
            }
            if (element.value.length>0) {
                element.previousElementSibling.classList.add('form-label--filled');
            }

            // Filling out fields
            element.addEventListener('blur', function(event) {
                if (!event.target.value) {
                    event.target.previousElementSibling.classList.remove('form-label--filled');
                }
            });
            element.addEventListener('focus', function(event) {
                event.target.previousElementSibling.classList.add('form-label--filled');
            });
        })
    }

    const formRadio = document.querySelectorAll('.form-radio')

    if(formRadio.length){
        formRadio.forEach(item => {  
            const wrapper = document.createElement("span");
            item.after(wrapper);
        });
    }

    const popup  = document.getElementById("modal-1");
    const popupContnet  = document.getElementById("popupContnet");
    function getInustryInfo(industry){
        
            var industryName = industry.getAttribute("data-industry");
            if(industryName){
                fetch('/industry/information?'+industryName,{
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer '+gqlToken,
                        'Xauthkey': gqlKey,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }),
                }).then(function (response) {
                    console.log('success!', response);
                    // The API call was successful!
                    return response.json();
                }).then(function (data) {
                    // This is the JSON from our response
                    console.log(data);
                                            
                            popup.classList.add('is-open');
                            popupContnet.innerHTML = data[0].popupDescription;
            
    
                    if(data.length){
                        return data;
                    }else{
                        console.warn('No data found for this industry');
                    }
                })
                .catch(function (err) {
                    // There was an error
                    console.warn('Something went wrong.', err);
                });
            } 
        }



    // const popup  = document.getElementById("modal-1");
    // const popupContnet  = document.getElementById("popupContnet");

    // const openPopup = document.querySelectorAll(".open-popussp");
    // if(openPopup.length){
    //     for (var i = 0; i < openPopup.length; i++) {
    //         openPopup[i].addEventListener('click',  function(e) {
    //             e.stopPropagation()
    //             console.log("You clicked on me!")
    //             var industryName = this.getAttribute('data-industry');
    //             console.log(industryName);
    //             if(industryName){
    //                 fetch('/industry/information?'+industryName,{
    //                     method: 'get',
    //                     headers: new Headers({
    //                         'Authorization': 'Bearer '+gqlToken,
    //                         'Xauthkey': gqlKey,
    //                         'Content-Type': 'application/x-www-form-urlencoded'
    //                     }),
    //                 }).then(function (response) {
    //                     console.log('success!', response);
    //                     // The API call was successful!
    //                     return response.json();
    //                 }).then(function (data) {
    //                     // This is the JSON from our response
    //                     console.log(data);
    //                     popup.classList.add('is-open');
    //                     popupContnet.innerHTML = data[0].popupDescription;
        
                        
    //                     if(data.length){
    //                         return data;
    //                     }else{
    //                         console.warn('No data found for this industry');
    //                     }
    //                 })
    //                 .catch(function (err) {
    //                     // There was an error
    //                     console.warn('Something went wrong.', err);
    //                 });
    //             }
    //         });

    //     }
    // }

    var openPopup = function (modal) {
        console.log("openPopup :)");
        // Look for video iframe in the modal
        if(popup){
            console.log(modal);
            // var industry = modal.querySelector('[data-industry]');
            // console.log(industry);

            // getInustryInfo(industry)
        }

    };

    // click to open recruitment tiles at mobile 
    const tileTriggers = document.querySelectorAll('.js-tile-open'),
    tileClose = document.querySelectorAll('.js-close-overlay');

    if(tileTriggers.length){

        const breakpointTiles = window.matchMedia( '(min-width:1023px)' );// keep track of swiper instances to destroy later
        
        const breakpointChecker = function() {

            if ( breakpointTiles.matches === true ) {


            } else if ( breakpointTiles.matches === false ) {

                for (var i = 0; i < tileTriggers.length; i++) {
                    tileTriggers[i].addEventListener('click',  function(e) {
                        e.stopPropagation()
                        this.classList.add('open');
                    });
                }

                for (var i = 0; i < tileClose.length; i++) {
                    tileClose[i].addEventListener('click',  function(e) {
                        e.stopPropagation()
                        this.closest('.js-tile-open').classList.remove('open');
                    });
                }

            }};

        // keep an eye on viewport size changes
        breakpointTiles.addListener(breakpointChecker);// kickstart
        breakpointChecker();    

    }

    const lottieHomeBanner = document.getElementById("lottie-home-banner");

    if (lottieHomeBanner) {
        const animation = bodymovin.loadAnimation({
          container: lottieHomeBanner,
          path: "/lottie/header.json",
          renderer: "svg",
          loop: true,
          autoplay: true,
        });
    }

    const lottieRoleBanner = document.getElementById("lottie-role-banner");

    if (lottieRoleBanner) {
        const animation = bodymovin.loadAnimation({
          container: lottieRoleBanner,
          path: "/lottie/no-exp-needed.json",
          renderer: "svg",
          loop: true,
          autoplay: true,
        });
    }

     const lottieForm = document.getElementById("lottie-form");

    if (lottieForm) {
        const animation = bodymovin.loadAnimation({
          container: lottieForm,
          path: "/lottie/button.json",
          renderer: "svg",
          loop: true,
          autoplay: true,
        });
    }



    // Bind event
    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})